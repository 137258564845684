import './siteheader.scss';

class SiteHeader {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-siteheader',
            eventScroller: null
        };

        this.settings = Object.assign({}, defaults, options);

        this.$siteHeader = element;
        this.eventScroller = this.settings.eventScroller;
        this.siteHeaderTopPosition = this.$siteHeader.offsetTop;
        this.$searchToggle = this.$siteHeader.querySelector('[data-search="root-toggle"]');
        this.$searchToggleHandle = this.$siteHeader.querySelector('.siteheader__search-handle');
        this.$searchInput = this.$siteHeader.querySelector('input[name="term"]');

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    checkHeaderPosition () {
        if (this.eventScroller) {
            const currentScrollPosition = this.eventScroller.getScrollInfo().position;

            if (currentScrollPosition >= this.siteHeaderTopPosition) {
                this.$siteHeader.classList.add('is--fixed');
            } else {
                this.$siteHeader.classList.remove('is--fixed');
            }
        }
    }

    setEvents () {
        if (this.eventScroller) {
            this.eventScroller.customFunctions.push(() => {
                this.checkHeaderPosition();
            });
            this.checkHeaderPosition();
        }

        if (this.$searchToggleHandle) {
            this.$searchToggleHandle.addEventListener('click', () => {
                if (this.$searchToggle.checked === false) {
                    this.$searchInput.classList.add('is--manually');
                }
            });
        }

        this.$searchInput.addEventListener('blur', () => {
            if (!this.$searchInput.classList.contains('is--manually')) {
                this.$searchToggle.checked = false;
            }
        });

        this.$searchInput.addEventListener('focus', () => {
            if (!this.$searchInput.classList.contains('is--manually')) {
                this.$searchToggle.checked = true;
            }
        });
    }
}

export { SiteHeader };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;
    const eventScroller = eventDetails.$scroll;

    const $siteHeader = $context.querySelector('[data-siteheader="root"]');

    if ($siteHeader) {
        const siteHeader = new SiteHeader($siteHeader, {
            eventScroller: eventScroller
        });
        siteHeader.initialize();
    }
});
